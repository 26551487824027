// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

import React from 'react';
import './App.css';
import { TextField, Button } from '@mui/material';

function App() {
  const [showForm, setShowForm] = React.useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowForm(false);
  };

  return (
    <div className="App">
      {showForm ? (
        <form onSubmit={handleSubmit} className="form" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <TextField label="Name" variant="outlined" name="name" />
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </form>
      ) : (
        <h1 className="welcome">Welcome to my app!</h1>
      )}
    </div>
  );
}

export default App;
